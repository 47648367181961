"use client";

import { Variant } from "@/components";
import { Surface as RunSurface } from "@/lib/graphql/types";
import * as Strings from "@/lib/strings";
import { faRoad } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { SurfaceOptionMap } from "./optionMap";

export interface SurfaceProps {
	surface: RunSurface[] | undefined | null;
	variant?: Variant;
}

export const Surface = ({ surface, variant = "primary" }: SurfaceProps) => {
	if (!surface || _.isEmpty(surface)) return <></>;

	const formatted = surface.map((i) => SurfaceOptionMap.get(i) || Strings.FallbackOptionLabel).join(", ");

	return variant === "primary" ? (
		<div className="meta">
			<span>
				<Tooltip title="Surface">
					<FontAwesomeIcon icon={faRoad} />
				</Tooltip>
			</span>
			{formatted}
		</div>
	) : (
		<>{formatted}</>
	);
};
