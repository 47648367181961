"use client";

import * as Strings from "@/lib/strings";
import { faRunning } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { GroupSizeOptionMap } from "./optionMap";

export interface GroupSizeProps {
	groupSize: string[] | undefined | null;
}

export const GroupSize = ({ groupSize }: GroupSizeProps) => {
	if (!groupSize || _.isEmpty(groupSize)) return <></>;

	return (
		<div className="meta">
			<span>
				<Tooltip title="Group Size">
					<FontAwesomeIcon icon={faRunning} />
				</Tooltip>
			</span>
			{(() => {
				return groupSize.map((i) => GroupSizeOptionMap.get(i) || Strings.FallbackOptionLabel).join(", ");
			})()}
		</div>
	);
};
