// NOTE: this is a placeholder file for localizable strings. If you find
// strings that should eventually be localized, put there here so they're
// easy to find later on.

export const FallbackLocale = "en-US";

export const FallbackOptionLabel = "TBD";

export const FormatDateString = "MMMM dd, yyyy";

export const LabelOn = "on";

export const LabelUntil = "until";

export const LabelShowQrCode = "Show QR Code";

export const LabelHideQrCode = "Show QR Code";
