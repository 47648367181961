import { Run, RunOrganizerNode, RunOrganizerRole } from "@/lib/graphql/types";

export type Hosts = {
	organizer: RunOrganizerNode | null;
	hosts: RunOrganizerNode[] | [];
};

export const reduce = (run?: Run): Hosts => {
	return (
		(run && {
			organizer: reduceOrganizers(run),
			hosts: reduceHosts(run)
		}) || {
			organizer: null,
			hosts: []
		}
	);
};

const reduceOrganizers = (run: Run) => {
	return (
		(run.organizers &&
			run.organizers.edges &&
			run.organizers.edges.find((e) => !!(e.role === RunOrganizerRole.Host))?.node) ||
		null
	);
};

const reduceHosts = (run: Run) => {
	return (
		(run.organizers &&
			run.organizers.edges &&
			run.organizers.edges
				.filter((e) => !!(e.role === RunOrganizerRole.Cohost))
				.map((e) => e.node)
				.filter((e) => e !== undefined && e !== null)) ||
		[]
	);
};
