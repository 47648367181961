import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTime: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String']['output'];
};

export type Club = Node & {
  __typename?: 'Club';
  approvedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  approvedBy?: Maybe<UserConnection>;
  createdAt: Scalars['AWSTimestamp']['output'];
  createdBy: UserConnection;
  /**   -- */
  email?: Maybe<Scalars['AWSEmail']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
  photos?: Maybe<Array<Scalars['String']['output']>>;
  runs?: Maybe<RunConnection>;
  status: Status;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedBy?: Maybe<UserConnection>;
};


export type ClubRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type ClubQueryResult = {
  __typename?: 'ClubQueryResult';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  nodes?: Maybe<Array<Club>>;
  pageInfo: PageInfo;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export enum CovidStatus {
  Distance = 'DISTANCE',
  Hold = 'HOLD',
  Mask = 'MASK',
  None = 'NONE'
}

export enum Days {
  F = 'F',
  M = 'M',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  W = 'W'
}

export enum Difficulty {
  Easy = 'EASY',
  Hard = 'HARD',
  Insane = 'INSANE',
  Intermediate = 'INTERMEDIATE',
  Sexy = 'SEXY'
}

export type Distance = {
  __typename?: 'Distance';
  measure: Scalars['Int']['output'];
  units: DistanceUnits;
};

export enum DistanceUnits {
  Fathoms = 'FATHOMS',
  Feet = 'FEET',
  Kilofeet = 'KILOFEET',
  Kilometers = 'KILOMETERS',
  Meters = 'METERS',
  Miles = 'MILES'
}

export type FilteredResult = {
  __typename?: 'FilteredResult';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  nodes?: Maybe<Array<FilteredResultNode>>;
  pageInfo: PageInfo;
};

export type FilteredResultNode = Club | Run | Store;

export type GrfNode = {
  approvedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  approvedBy?: Maybe<UserConnection>;
  createdAt: Scalars['AWSTimestamp']['output'];
  createdBy: UserConnection;
  id: Scalars['ID']['output'];
  location: Location;
  name: Scalars['String']['output'];
  photos?: Maybe<Array<Scalars['String']['output']>>;
  status: Status;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedBy?: Maybe<UserConnection>;
};

export type Link = {
  __typename?: 'Link';
  type: LinkType;
  url: Scalars['AWSURL']['output'];
};

export enum LinkType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Strava = 'STRAVA',
  Twitter = 'TWITTER',
  Ultrasignup = 'ULTRASIGNUP',
  Website = 'WEBSITE'
}

export type Location = {
  __typename?: 'Location';
  address: Scalars['String']['output'];
  coordinates?: Maybe<Coordinates>;
  geohash?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Region>;
};

/**   -- */
export type Node = {
  id: Scalars['ID']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  club?: Maybe<Club>;
  clubs?: Maybe<ClubQueryResult>;
  inGeohash?: Maybe<FilteredResult>;
  inRegion?: Maybe<FilteredResult>;
  run?: Maybe<Run>;
  runs?: Maybe<RunQueryResult>;
  store?: Maybe<Store>;
  stores?: Maybe<StoreQueryResult>;
  user?: Maybe<User>;
  users?: Maybe<UserQueryResult>;
};


export type QueryClubArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClubsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInGeohashArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  geohash: Scalars['String']['input'];
};


export type QueryInRegionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  region: RegionSearchInput;
};


export type QueryRunArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStoreArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export enum Recurrence {
  A = 'A',
  B = 'B',
  D = 'D',
  M = 'M',
  O = 'O',
  Q = 'Q',
  S = 'S',
  W = 'W'
}

export type Region = {
  __typename?: 'Region';
  country?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  world?: Maybe<Scalars['String']['output']>;
};

export type RegionSearchInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  world: Scalars['String']['input'];
};

export type Run = GrfNode & Node & {
  __typename?: 'Run';
  approvedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  approvedBy?: Maybe<UserConnection>;
  /**  -- */
  covid?: Maybe<CovidStatus>;
  createdAt: Scalars['AWSTimestamp']['output'];
  createdBy: UserConnection;
  days: Array<Days>;
  difficulty?: Maybe<Array<Difficulty>>;
  distance?: Maybe<Array<Distance>>;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  groupSize?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  links?: Maybe<Array<Link>>;
  location: Location;
  meta?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organizers?: Maybe<RunOrganizerConnection>;
  photos?: Maybe<Array<Scalars['String']['output']>>;
  postRunEvent?: Maybe<Array<Scalars['String']['output']>>;
  recurs: Recurrence;
  short: Scalars['String']['output'];
  similar?: Maybe<RunBatchConnection>;
  startDate?: Maybe<Scalars['AWSDate']['output']>;
  startTime: Scalars['AWSTime']['output'];
  status: Status;
  statusReason?: Maybe<Scalars['String']['output']>;
  surface?: Maybe<Array<Surface>>;
  type?: Maybe<Array<RunType>>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedBy?: Maybe<UserConnection>;
  vert?: Maybe<Array<Scalars['String']['output']>>;
};


export type RunDistanceArgs = {
  units?: InputMaybe<DistanceUnits>;
};


export type RunOrganizersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type RunSimilarArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type RunBatchConnection = {
  __typename?: 'RunBatchConnection';
  count: Scalars['Int']['output'];
  edges?: Maybe<Array<RunEdge>>;
  unprocessedKeys?: Maybe<Array<Scalars['String']['output']>>;
};

export type RunConnection = {
  __typename?: 'RunConnection';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  edges?: Maybe<Array<RunEdge>>;
  pageInfo: PageInfo;
};

export type RunEdge = {
  __typename?: 'RunEdge';
  node?: Maybe<Run>;
  role?: Maybe<RunOrganizerRole>;
  runId: Scalars['ID']['output'];
  runName?: Maybe<Scalars['String']['output']>;
};

export type RunOrganizerConnection = {
  __typename?: 'RunOrganizerConnection';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  edges?: Maybe<Array<RunOrganizerEdge>>;
  pageInfo: PageInfo;
};

export type RunOrganizerEdge = {
  __typename?: 'RunOrganizerEdge';
  node?: Maybe<RunOrganizerNode>;
  organizerId: Scalars['ID']['output'];
  organizerName?: Maybe<Scalars['String']['output']>;
  role: RunOrganizerRole;
  runId: Scalars['ID']['output'];
  runName?: Maybe<Scalars['String']['output']>;
  type: RunOrganizerType;
};

export type RunOrganizerNode = Club | Store | User;

export enum RunOrganizerRole {
  Cohost = 'COHOST',
  Host = 'HOST'
}

export enum RunOrganizerType {
  Club = 'CLUB',
  Store = 'STORE',
  User = 'USER'
}

export type RunQueryResult = {
  __typename?: 'RunQueryResult';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  nodes?: Maybe<Array<Run>>;
  pageInfo: PageInfo;
};

export enum RunType {
  Other = 'OTHER',
  Social = 'SOCIAL',
  Speedwork = 'SPEEDWORK',
  Structured = 'STRUCTURED'
}

export enum Status {
  A = 'A',
  C = 'C',
  P = 'P',
  R = 'R'
}

export type Store = Node & {
  __typename?: 'Store';
  approvedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  approvedBy?: Maybe<UserConnection>;
  createdAt: Scalars['AWSTimestamp']['output'];
  createdBy: UserConnection;
  /**   -- */
  email?: Maybe<Scalars['AWSEmail']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
  photos?: Maybe<Array<Scalars['String']['output']>>;
  runs?: Maybe<RunConnection>;
  status: Status;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedBy?: Maybe<UserConnection>;
};


export type StoreRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreQueryResult = {
  __typename?: 'StoreQueryResult';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  nodes?: Maybe<Array<Store>>;
  pageInfo: PageInfo;
};

export enum Surface {
  Gravel = 'GRAVEL',
  Other = 'OTHER',
  Road = 'ROAD',
  Track = 'TRACK',
  Trail = 'TRAIL'
}

export type User = Node & {
  __typename?: 'User';
  approvedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  approvedBy?: Maybe<UserConnection>;
  createdAt: Scalars['AWSTimestamp']['output'];
  createdBy: UserConnection;
  /**   -- */
  email?: Maybe<Scalars['AWSEmail']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
  photos?: Maybe<Array<Scalars['String']['output']>>;
  registeredAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  runs?: Maybe<RunConnection>;
  status: Status;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedBy?: Maybe<UserConnection>;
  username?: Maybe<Scalars['String']['output']>;
};


export type UserRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edge?: Maybe<UserEdge>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  id?: Maybe<Scalars['ID']['output']>;
  node?: Maybe<User>;
};

export type UserQueryResult = {
  __typename?: 'UserQueryResult';
  count: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  nodes?: Maybe<Array<User>>;
  pageInfo: PageInfo;
};

export type ClubQueryBaseFieldsFragment = { __typename?: 'ClubQueryResult', count: number, cursor?: string | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } };

export type RunQueryBaseFieldsFragment = { __typename?: 'RunQueryResult', count: number, cursor?: string | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } };

export type StoreQueryBaseFieldsFragment = { __typename?: 'StoreQueryResult', count: number, cursor?: string | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } };

export type UserQueryBaseFieldsFragment = { __typename?: 'UserQueryResult', count: number, cursor?: string | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } };

export type AlgoliaSyncQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AlgoliaSyncQuery = { __typename?: 'Query', runs?: { __typename?: 'RunQueryResult', nodes?: Array<{ __typename?: 'Run', id: string, approvedAt?: number | null, createdAt: number, name: string, status: Status, updatedAt?: number | null, days: Array<Days>, difficulty?: Array<Difficulty> | null, endDate?: string | null, groupSize?: Array<string> | null, meta?: Array<string> | null, notes?: string | null, recurs: Recurrence, startDate?: string | null, startTime: string, surface?: Array<Surface> | null, type?: Array<RunType> | null, vert?: Array<string> | null, postRunEvent?: Array<string> | null, location: { __typename?: 'Location', address: string, geohash?: string | null, coordinates?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null, region?: { __typename?: 'Region', world?: string | null, country?: string | null, state?: string | null, postal?: string | null } | null }, distance?: Array<{ __typename?: 'Distance', measure: number, units: DistanceUnits }> | null, organizers?: { __typename?: 'RunOrganizerConnection', edges?: Array<{ __typename?: 'RunOrganizerEdge', node?: { __typename?: 'Club', id: string, name: string } | { __typename?: 'Store', id: string, name: string } | { __typename?: 'User', name: string, id: string } | null }> | null } | null }> | null } | null };

export type SitemapQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SitemapQuery = { __typename?: 'Query', runs?: { __typename?: 'RunQueryResult', count: number, cursor?: string | null, nodes?: Array<{ __typename?: 'Run', id: string, name: string, status: Status }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type ClubListViewQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ClubListViewQuery = { __typename?: 'Query', clubs?: { __typename?: 'ClubQueryResult', count: number, cursor?: string | null, nodes?: Array<{ __typename?: 'Club', id: string, name: string, status: Status }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type RunIdRedirectionViewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RunIdRedirectionViewQuery = { __typename?: 'Query', run?: { __typename?: 'Run', id: string, name: string, status: Status } | null };

export type RunDetailViewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RunDetailViewQuery = { __typename?: 'Query', run?: { __typename?: 'Run', approvedAt?: number | null, createdAt: number, days: Array<Days>, difficulty?: Array<Difficulty> | null, endDate?: string | null, groupSize?: Array<string> | null, id: string, meta?: Array<string> | null, name: string, notes?: string | null, photos?: Array<string> | null, postRunEvent?: Array<string> | null, recurs: Recurrence, short: string, status: Status, updatedAt?: number | null, startDate?: string | null, startTime: string, surface?: Array<Surface> | null, type?: Array<RunType> | null, vert?: Array<string> | null, distance?: Array<{ __typename?: 'Distance', measure: number, units: DistanceUnits }> | null, links?: Array<{ __typename?: 'Link', type: LinkType, url: string }> | null, location: { __typename?: 'Location', address: string, geohash?: string | null, coordinates?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null, region?: { __typename?: 'Region', world?: string | null, country?: string | null, state?: string | null, postal?: string | null } | null }, organizers?: { __typename?: 'RunOrganizerConnection', count: number, cursor?: string | null, edges?: Array<{ __typename?: 'RunOrganizerEdge', organizerId: string, runId: string, organizerName?: string | null, role: RunOrganizerRole, runName?: string | null, type: RunOrganizerType, node?: { __typename: 'Club', name: string, id: string } | { __typename: 'Store', name: string, id: string } | { __typename: 'User', name: string, id: string } | null }> | null } | null, similar?: { __typename?: 'RunBatchConnection', count: number, edges?: Array<{ __typename?: 'RunEdge', runId: string, runName?: string | null }> | null } | null } | null };

export type RunDetailViewPathsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RunDetailViewPathsQuery = { __typename?: 'Query', runs?: { __typename?: 'RunQueryResult', count: number, cursor?: string | null, nodes?: Array<{ __typename?: 'Run', id: string, name: string, status: Status }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type RunMapViewQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RunMapViewQuery = { __typename?: 'Query', runs?: { __typename?: 'RunQueryResult', count: number, cursor?: string | null, nodes?: Array<{ __typename?: 'Run', id: string, name: string, status: Status, location: { __typename?: 'Location', address: string, coordinates?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null } }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type RunListViewQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RunListViewQuery = { __typename?: 'Query', runs?: { __typename?: 'RunQueryResult', count: number, cursor?: string | null, nodes?: Array<{ __typename?: 'Run', id: string, name: string, status: Status }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type StoreListViewQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type StoreListViewQuery = { __typename?: 'Query', stores?: { __typename?: 'StoreQueryResult', count: number, cursor?: string | null, nodes?: Array<{ __typename?: 'Store', id: string, name: string, status: Status }> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export const ClubQueryBaseFieldsFragmentDoc = gql`
    fragment ClubQueryBaseFields on ClubQueryResult {
  count
  cursor
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
}
    `;
export const RunQueryBaseFieldsFragmentDoc = gql`
    fragment RunQueryBaseFields on RunQueryResult {
  count
  cursor
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
}
    `;
export const StoreQueryBaseFieldsFragmentDoc = gql`
    fragment StoreQueryBaseFields on StoreQueryResult {
  count
  cursor
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
}
    `;
export const UserQueryBaseFieldsFragmentDoc = gql`
    fragment UserQueryBaseFields on UserQueryResult {
  count
  cursor
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
}
    `;
export const AlgoliaSyncDocument = gql`
    query AlgoliaSync($first: Int) {
  runs(first: $first) {
    nodes {
      id
      approvedAt
      createdAt
      location {
        address
        geohash
        coordinates {
          latitude
          longitude
        }
        region {
          world
          country
          state
          postal
        }
      }
      name
      status
      updatedAt
      days
      difficulty
      distance {
        measure
        units
      }
      endDate
      groupSize
      meta
      notes
      recurs
      startDate
      startTime
      surface
      type
      vert
      postRunEvent
      organizers {
        edges {
          node {
            ... on Store {
              id
              name
            }
            ... on User {
              name
              id
            }
            ... on Club {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAlgoliaSyncQuery__
 *
 * To run a query within a React component, call `useAlgoliaSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlgoliaSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlgoliaSyncQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAlgoliaSyncQuery(baseOptions?: Apollo.QueryHookOptions<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>(AlgoliaSyncDocument, options);
      }
export function useAlgoliaSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>(AlgoliaSyncDocument, options);
        }
export function useAlgoliaSyncSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>(AlgoliaSyncDocument, options);
        }
export type AlgoliaSyncQueryHookResult = ReturnType<typeof useAlgoliaSyncQuery>;
export type AlgoliaSyncLazyQueryHookResult = ReturnType<typeof useAlgoliaSyncLazyQuery>;
export type AlgoliaSyncSuspenseQueryHookResult = ReturnType<typeof useAlgoliaSyncSuspenseQuery>;
export type AlgoliaSyncQueryResult = Apollo.QueryResult<AlgoliaSyncQuery, AlgoliaSyncQueryVariables>;
export const SitemapDocument = gql`
    query Sitemap($first: Int) {
  runs(first: $first) {
    ...RunQueryBaseFields
    nodes {
      id
      name
      status
    }
  }
}
    ${RunQueryBaseFieldsFragmentDoc}`;

/**
 * __useSitemapQuery__
 *
 * To run a query within a React component, call `useSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSitemapQuery(baseOptions?: Apollo.QueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
      }
export function useSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
        }
export function useSitemapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
        }
export type SitemapQueryHookResult = ReturnType<typeof useSitemapQuery>;
export type SitemapLazyQueryHookResult = ReturnType<typeof useSitemapLazyQuery>;
export type SitemapSuspenseQueryHookResult = ReturnType<typeof useSitemapSuspenseQuery>;
export type SitemapQueryResult = Apollo.QueryResult<SitemapQuery, SitemapQueryVariables>;
export const ClubListViewDocument = gql`
    query ClubListView($first: Int) {
  clubs(first: $first) {
    ...ClubQueryBaseFields
    nodes {
      id
      name
      status
    }
  }
}
    ${ClubQueryBaseFieldsFragmentDoc}`;

/**
 * __useClubListViewQuery__
 *
 * To run a query within a React component, call `useClubListViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubListViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubListViewQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useClubListViewQuery(baseOptions?: Apollo.QueryHookOptions<ClubListViewQuery, ClubListViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClubListViewQuery, ClubListViewQueryVariables>(ClubListViewDocument, options);
      }
export function useClubListViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClubListViewQuery, ClubListViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClubListViewQuery, ClubListViewQueryVariables>(ClubListViewDocument, options);
        }
export function useClubListViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClubListViewQuery, ClubListViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClubListViewQuery, ClubListViewQueryVariables>(ClubListViewDocument, options);
        }
export type ClubListViewQueryHookResult = ReturnType<typeof useClubListViewQuery>;
export type ClubListViewLazyQueryHookResult = ReturnType<typeof useClubListViewLazyQuery>;
export type ClubListViewSuspenseQueryHookResult = ReturnType<typeof useClubListViewSuspenseQuery>;
export type ClubListViewQueryResult = Apollo.QueryResult<ClubListViewQuery, ClubListViewQueryVariables>;
export const RunIdRedirectionViewDocument = gql`
    query RunIdRedirectionView($id: ID!) {
  run(id: $id) {
    id
    name
    status
  }
}
    `;

/**
 * __useRunIdRedirectionViewQuery__
 *
 * To run a query within a React component, call `useRunIdRedirectionViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunIdRedirectionViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunIdRedirectionViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunIdRedirectionViewQuery(baseOptions: Apollo.QueryHookOptions<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables> & ({ variables: RunIdRedirectionViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables>(RunIdRedirectionViewDocument, options);
      }
export function useRunIdRedirectionViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables>(RunIdRedirectionViewDocument, options);
        }
export function useRunIdRedirectionViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables>(RunIdRedirectionViewDocument, options);
        }
export type RunIdRedirectionViewQueryHookResult = ReturnType<typeof useRunIdRedirectionViewQuery>;
export type RunIdRedirectionViewLazyQueryHookResult = ReturnType<typeof useRunIdRedirectionViewLazyQuery>;
export type RunIdRedirectionViewSuspenseQueryHookResult = ReturnType<typeof useRunIdRedirectionViewSuspenseQuery>;
export type RunIdRedirectionViewQueryResult = Apollo.QueryResult<RunIdRedirectionViewQuery, RunIdRedirectionViewQueryVariables>;
export const RunDetailViewDocument = gql`
    query RunDetailView($id: ID!) {
  run(id: $id) {
    approvedAt
    createdAt
    days
    difficulty
    distance {
      measure
      units
    }
    endDate
    groupSize
    id
    links {
      type
      url
    }
    location {
      address
      coordinates {
        latitude
        longitude
      }
      geohash
      region {
        world
        country
        state
        postal
      }
    }
    meta
    name
    notes
    organizers {
      count
      cursor
      edges {
        node {
          ... on User {
            name
            __typename
            id
          }
          ... on Store {
            name
            __typename
            id
          }
          ... on Club {
            name
            __typename
            id
          }
        }
        organizerId
        runId
        organizerName
        role
        runName
        type
      }
    }
    photos
    postRunEvent
    recurs
    short
    similar {
      count
      edges {
        runId
        runName
      }
    }
    status
    updatedAt
    startDate
    startTime
    surface
    type
    vert
  }
}
    `;

/**
 * __useRunDetailViewQuery__
 *
 * To run a query within a React component, call `useRunDetailViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunDetailViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunDetailViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunDetailViewQuery(baseOptions: Apollo.QueryHookOptions<RunDetailViewQuery, RunDetailViewQueryVariables> & ({ variables: RunDetailViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunDetailViewQuery, RunDetailViewQueryVariables>(RunDetailViewDocument, options);
      }
export function useRunDetailViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunDetailViewQuery, RunDetailViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunDetailViewQuery, RunDetailViewQueryVariables>(RunDetailViewDocument, options);
        }
export function useRunDetailViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RunDetailViewQuery, RunDetailViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RunDetailViewQuery, RunDetailViewQueryVariables>(RunDetailViewDocument, options);
        }
export type RunDetailViewQueryHookResult = ReturnType<typeof useRunDetailViewQuery>;
export type RunDetailViewLazyQueryHookResult = ReturnType<typeof useRunDetailViewLazyQuery>;
export type RunDetailViewSuspenseQueryHookResult = ReturnType<typeof useRunDetailViewSuspenseQuery>;
export type RunDetailViewQueryResult = Apollo.QueryResult<RunDetailViewQuery, RunDetailViewQueryVariables>;
export const RunDetailViewPathsDocument = gql`
    query RunDetailViewPaths($first: Int) {
  runs(first: $first) {
    ...RunQueryBaseFields
    nodes {
      id
      name
      status
    }
  }
}
    ${RunQueryBaseFieldsFragmentDoc}`;

/**
 * __useRunDetailViewPathsQuery__
 *
 * To run a query within a React component, call `useRunDetailViewPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunDetailViewPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunDetailViewPathsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRunDetailViewPathsQuery(baseOptions?: Apollo.QueryHookOptions<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>(RunDetailViewPathsDocument, options);
      }
export function useRunDetailViewPathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>(RunDetailViewPathsDocument, options);
        }
export function useRunDetailViewPathsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>(RunDetailViewPathsDocument, options);
        }
export type RunDetailViewPathsQueryHookResult = ReturnType<typeof useRunDetailViewPathsQuery>;
export type RunDetailViewPathsLazyQueryHookResult = ReturnType<typeof useRunDetailViewPathsLazyQuery>;
export type RunDetailViewPathsSuspenseQueryHookResult = ReturnType<typeof useRunDetailViewPathsSuspenseQuery>;
export type RunDetailViewPathsQueryResult = Apollo.QueryResult<RunDetailViewPathsQuery, RunDetailViewPathsQueryVariables>;
export const RunMapViewDocument = gql`
    query RunMapView($first: Int) {
  runs(first: $first) {
    ...RunQueryBaseFields
    nodes {
      id
      location {
        address
        coordinates {
          latitude
          longitude
        }
      }
      name
      status
    }
  }
}
    ${RunQueryBaseFieldsFragmentDoc}`;

/**
 * __useRunMapViewQuery__
 *
 * To run a query within a React component, call `useRunMapViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunMapViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunMapViewQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRunMapViewQuery(baseOptions?: Apollo.QueryHookOptions<RunMapViewQuery, RunMapViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunMapViewQuery, RunMapViewQueryVariables>(RunMapViewDocument, options);
      }
export function useRunMapViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunMapViewQuery, RunMapViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunMapViewQuery, RunMapViewQueryVariables>(RunMapViewDocument, options);
        }
export function useRunMapViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RunMapViewQuery, RunMapViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RunMapViewQuery, RunMapViewQueryVariables>(RunMapViewDocument, options);
        }
export type RunMapViewQueryHookResult = ReturnType<typeof useRunMapViewQuery>;
export type RunMapViewLazyQueryHookResult = ReturnType<typeof useRunMapViewLazyQuery>;
export type RunMapViewSuspenseQueryHookResult = ReturnType<typeof useRunMapViewSuspenseQuery>;
export type RunMapViewQueryResult = Apollo.QueryResult<RunMapViewQuery, RunMapViewQueryVariables>;
export const RunListViewDocument = gql`
    query RunListView($first: Int) {
  runs(first: $first) {
    ...RunQueryBaseFields
    nodes {
      id
      name
      status
    }
  }
}
    ${RunQueryBaseFieldsFragmentDoc}`;

/**
 * __useRunListViewQuery__
 *
 * To run a query within a React component, call `useRunListViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunListViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunListViewQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRunListViewQuery(baseOptions?: Apollo.QueryHookOptions<RunListViewQuery, RunListViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunListViewQuery, RunListViewQueryVariables>(RunListViewDocument, options);
      }
export function useRunListViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunListViewQuery, RunListViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunListViewQuery, RunListViewQueryVariables>(RunListViewDocument, options);
        }
export function useRunListViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RunListViewQuery, RunListViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RunListViewQuery, RunListViewQueryVariables>(RunListViewDocument, options);
        }
export type RunListViewQueryHookResult = ReturnType<typeof useRunListViewQuery>;
export type RunListViewLazyQueryHookResult = ReturnType<typeof useRunListViewLazyQuery>;
export type RunListViewSuspenseQueryHookResult = ReturnType<typeof useRunListViewSuspenseQuery>;
export type RunListViewQueryResult = Apollo.QueryResult<RunListViewQuery, RunListViewQueryVariables>;
export const StoreListViewDocument = gql`
    query StoreListView($first: Int) {
  stores(first: $first) {
    ...StoreQueryBaseFields
    nodes {
      id
      name
      status
    }
  }
}
    ${StoreQueryBaseFieldsFragmentDoc}`;

/**
 * __useStoreListViewQuery__
 *
 * To run a query within a React component, call `useStoreListViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreListViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreListViewQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStoreListViewQuery(baseOptions?: Apollo.QueryHookOptions<StoreListViewQuery, StoreListViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreListViewQuery, StoreListViewQueryVariables>(StoreListViewDocument, options);
      }
export function useStoreListViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreListViewQuery, StoreListViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreListViewQuery, StoreListViewQueryVariables>(StoreListViewDocument, options);
        }
export function useStoreListViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StoreListViewQuery, StoreListViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StoreListViewQuery, StoreListViewQueryVariables>(StoreListViewDocument, options);
        }
export type StoreListViewQueryHookResult = ReturnType<typeof useStoreListViewQuery>;
export type StoreListViewLazyQueryHookResult = ReturnType<typeof useStoreListViewLazyQuery>;
export type StoreListViewSuspenseQueryHookResult = ReturnType<typeof useStoreListViewSuspenseQuery>;
export type StoreListViewQueryResult = Apollo.QueryResult<StoreListViewQuery, StoreListViewQueryVariables>;