"use client";

import { useTextblock } from "@/hooks/useTextblock";
import { textblockConfig } from "@/themes/grf/textblock.config";

export const Textblock = () => {
	useTextblock(textblockConfig);

	return <></>;
};
