"use client";

import { GrfConfig } from "@/../grf.config";
import { useAnalytics } from "@/hooks/useAnalytics";
import Link from "next/link";

export const Footer = () => {
	const location = "Footer";
	const copyright = <>&copy;{new Date().getFullYear()}&nbsp;</>;
	const spacer = <>&nbsp;&nbsp;|&nbsp;&nbsp;</>;
	const { events, track } = useAnalytics();

	return (
		<div className="footer-content">
			{`v${GrfConfig.version}`}
			{spacer}
			{copyright}
			<Link
				className="footerPrivacy"
				href="https://algorythmic.com/"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "Algorythmic"
					});
				}}
			>
				Algorythmic
			</Link>
			{spacer}
			<Link
				className="footerPrivacy"
				href="/privacy"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "Privacy"
					});
				}}
			>
				Privacy
			</Link>
			{spacer}
			<Link
				className="footerPrivacy"
				href="/clubs/list"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "All Clubs"
					});
				}}
			>
				All Clubs
			</Link>
			{spacer}
			<Link
				className="footerPrivacy"
				href="/runs/list"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "All Runs"
					});
				}}
			>
				All Runs
			</Link>
			{spacer}
			<Link
				className="footerPrivacy"
				href="/stores/list"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "All Stores"
					});
				}}
			>
				All Stores
			</Link>
			{spacer}
			<Link
				className="footerPrivacy"
				href="http://grf.link/donate"
				target="_blank"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "Donate"
					});
				}}
			>
				Donate
			</Link>
			{spacer}
			<Link
				className="footerPrivacy"
				href="http://grf.link/roadmap"
				rel="ugc"
				target="_blank"
				onClick={() => {
					track(events.LinkClicked, {
						location,
						name: "Roadmap"
					});
				}}
			>
				Roadmap
			</Link>
		</div>
	);
};
