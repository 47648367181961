"use client";

import { Variant } from "@/components";
import { Distance as RunDistance } from "@/lib/graphql/types";
import * as Strings from "@/lib/strings";
import { faRuler } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { DistanceOptionMap } from "./optionMap";

export interface DistanceProps {
	distance: RunDistance[] | undefined | null;
	variant?: Variant;
}

export const Distance = ({ distance, variant = "primary" }: DistanceProps) => {
	if (!distance || _.isEmpty(distance)) return <></>;

	const formatted = distance.map((i) => DistanceOptionMap.get(i.measure) || Strings.FallbackOptionLabel).join(", ");

	return variant === "primary" ? (
		<div className="meta">
			<span>
				<Tooltip title="Distance">
					<FontAwesomeIcon icon={faRuler} />
				</Tooltip>
			</span>
			{formatted}
		</div>
	) : (
		<>{formatted}</>
	);
};
