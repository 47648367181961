"use client";

export interface NotesProps {
	notes?: string | null;
}

export const Notes = ({ notes }: NotesProps) => {
	if (!notes) return <></>;

	return (
		<>
			<br />
			<div className="meta">{notes && <div>{notes}</div>}</div>
		</>
	);
};
