"use client";

import { Variant } from "@/components";
import { Box, useTheme } from "@mui/material";
import Image, { StaticImageData } from "next/image";
import Link from "next/link";
import IdDesktopPrimarySvg from "/public/group-run-finder--desktop-front.svg";
import IdDesktopDefaultSvg from "/public/group-run-finder--desktop.svg";
import IdMobileDefaultSvg from "/public/group-run-finder--mobile-480.svg";
import IdMobilePrimarySvg from "/public/group-run-finder--mobile-front.svg";

export interface LogoButtonProps {
	alt: string;
	variant: Variant;
	href: string;
	onClick?: () => void;
}

export const LogoButton = ({ alt, href, variant, onClick }: LogoButtonProps) => {
	const theme = useTheme();

	const idDesktopDefault = IdDesktopDefaultSvg as StaticImageData;
	const idDesktopPrimary = IdDesktopPrimarySvg as StaticImageData;
	const idMobileDefault = IdMobileDefaultSvg as StaticImageData;
	const idMobilePrimary = IdMobilePrimarySvg as StaticImageData;

	return (
		<Link href={href} onClick={onClick}>
			<div style={{ marginLeft: "1em", marginRight: "1em" }}>
				<Box
					component={"span"}
					sx={{
						[theme.breakpoints.down("sm")]: {
							display: "none"
						}
					}}
				>
					<Image
						alt={alt}
						height="44"
						width="120"
						priority
						style={{
							pointerEvents: "none",
							transform: "none",
							width: "120px"
						}}
						src={variant === "primary" ? idDesktopPrimary.src : idDesktopDefault.src}
					/>
				</Box>
				<Box
					component={"span"}
					sx={{
						display: "none",
						[theme.breakpoints.down("sm")]: {
							display: "flex"
						}
					}}
				>
					<Image
						alt={alt}
						height="41"
						width="112"
						priority
						src={variant === "primary" ? idMobilePrimary.src : idMobileDefault.src}
						style={variant === "primary" ? { width: "112px" } : { width: "35px" }}
					/>
				</Box>
			</div>
		</Link>
	);
};
