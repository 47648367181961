import { AmplifyConfig } from "@/lib/services/amplify";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { createFragmentRegistry } from "@apollo/client/cache";
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import _ from "lodash";
import { GrfConfig } from "../../grf.config";
import {
	ClubQueryBaseFields,
	RunQueryBaseFields,
	StoreQueryBaseFields,
	UserQueryBaseFields
} from "./graphql/fragments";

// TODO: update to support authenticated state
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/531#issuecomment-657365166\
// https://github.com/awslabs/aws-mobile-appsync-sdk-js?tab=readme-ov-file#using-authorization-and-subscription-links-with-apollo-client-v3-no-offline-support

const apiKey = AmplifyConfig.API.GraphQL.apiKey!;
const region = AmplifyConfig.API.GraphQL.region;
const url = AmplifyConfig.API.GraphQL.endpoint;

let client: ApolloClient<NormalizedCacheObject> | null = null;

// this client is the standard mechanism for SSR and CSR rendering, and is the
// client that is returned by initializeApollo. it supports Cognito authentication
// and is what should be used in most cases

const createApolloClient = () => {
	return new ApolloClient<NormalizedCacheObject>({
		connectToDevTools: enableDevTools(),
		cache: new InMemoryCache({
			fragments: createFragmentRegistry(
				ClubQueryBaseFields,
				RunQueryBaseFields,
				StoreQueryBaseFields,
				UserQueryBaseFields
			)
		}),
		link: ApolloLink.from([
			createAuthLink({
				url,
				region,
				auth: {
					type: AUTH_TYPE.API_KEY,
					apiKey
				}
			}),
			// createAuthLink({
			// 	url,
			// 	region,
			// 	auth: {
			// 		type: AUTH_TYPE.AWS_IAM,
			// 		credentials: () =>
			// 			fetchAuthSession().then((s) => {
			// 				return s.credentials || null;
			// 			})
			// 	}
			// }),
			new HttpLink({
				uri: url
			})
		]),
		name: "grf-web",
		ssrMode: typeof window === "undefined",
		version: GrfConfig.version
	});
};

// this client is for use by API routes that have ephemeral usage. it is set up
// to strip the __typename field from responses, which has negative implications
// for caching; use initializeApollo if you need client instance for SSR or CSR

export const createApolloApiClient = () => {
	return new ApolloClient<NormalizedCacheObject>({
		assumeImmutableResults: true,
		cache: new InMemoryCache({
			addTypename: false,
			fragments: createFragmentRegistry(
				ClubQueryBaseFields,
				RunQueryBaseFields,
				StoreQueryBaseFields,
				UserQueryBaseFields
			)
		}),
		link: ApolloLink.from([
			createAuthLink({
				url,
				region,
				auth: {
					type: AUTH_TYPE.API_KEY,
					apiKey
				}
			}),
			new HttpLink({
				uri: url
			})
		]),
		name: "grf-web-api",
		ssrMode: true,
		version: GrfConfig.version
	});
};

// initializes a version of the client that shares it's cache between SSR and CSR calls

export const initializeApollo = (initialState: NormalizedCacheObject | null = null) => {
	const ac = client ?? createApolloClient();

	if (initialState) {
		// SSR hydration; merge caches
		const existingCache = ac.extract();
		const data = _.mergeWith(initialState, existingCache, (destination: [], source: []) => {
			source.concat(destination.filter((d) => source.every((s) => !_.isEqual(d, s))));
		});
		ac.cache.restore(data);
	}

	if (typeof window === "undefined") return ac; // SSR

	if (!client) client = ac; // CSR

	return ac;
};

// allow manual enabling/disabling of Apollo dev tools via the environment

const enableDevTools = () => {
	const isCsr = typeof window !== "undefined";
	if (!isCsr) return false;

	let enabled = process.env.NODE_ENV !== "production";
	if (process.env.NEXT_PUBLIC_APOLLO_DEV_TOOLS) {
		if (process.env.NEXT_PUBLIC_APOLLO_DEV_TOOLS === "0") {
			// allow forcing off state regardless of environment
			console.debug("[GRF] ADT: disabled manually");
			enabled = false;
		} else {
			// allow forcing off state regardless of environment
			console.debug("[GRF] ADT: enabled manually");
			enabled = true;
		}
	}

	console.debug(`[GRF] ADT: ${enabled.toString()}`);

	return enabled;
};
