"use client";

import { Variant } from "@/components";
import { RunOrganizerNode } from "@/lib/graphql/types";
import _ from "lodash";

export interface HostProps {
	hosts: Array<RunOrganizerNode> | undefined | null;
	variant?: Variant;
}

export const Host = ({ hosts, variant = "primary" }: HostProps) => {
	const formatHostString = (hosts?: Array<RunOrganizerNode> | null) => {
		if (!hosts || _.isEmpty(hosts)) return;

		if (hosts.length > 1) {
			// only display the first two hosts
			return `${hosts[0].name} and ${hosts[1].name}`;
		}

		return hosts[0].name;
	};

	if (!hosts) return <></>;

	const hostInfo = formatHostString(hosts);
	const hostedByLabel = "Hosted by";

	return variant === "primary" ? (
		<>
			{hostInfo && (
				<h2 className="hostedBy">
					<i>
						{hostedByLabel} {hostInfo}
					</i>
				</h2>
			)}
		</>
	) : (
		<>
			{hostInfo && (
				<p>
					{hostedByLabel} {hostInfo}
				</p>
			)}
		</>
	);
};
