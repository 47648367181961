"use client";

import { DayOptionMap, DifficultyOptionMap, SurfaceOptionMap, TypeOptionMap } from "@/components";
import { Days, Difficulty, RunOrganizerNode, RunType, Surface } from "@/lib/graphql/types";
import { slug } from "@/lib/util/slug";
import _ from "lodash";
import { JsonLd } from "react-schemaorg";
import { Event } from "schema-dts";

export interface EventSchemaProps {
	address: string;
	days: Days[];
	difficulty: Difficulty[] | undefined | null;
	endDate?: string;
	hosts: Array<RunOrganizerNode> | undefined | null;
	id: string;
	name: string;
	startDate?: string;
	surface: Surface[] | undefined | null;
	type: RunType[] | undefined | null;
}

export const EventSchema = ({
	address,
	days,
	difficulty,
	endDate,
	hosts,
	id,
	name,
	startDate,
	surface,
	type
}: EventSchemaProps) => {
	const formatKeywordString = (
		difficulty?: Difficulty[] | null,
		surface?: Surface[] | null,
		type?: RunType[] | null
	) => {
		let keywords = "group run,run";

		if (difficulty)
			keywords +=
				", " +
				difficulty
					.map((i) => DifficultyOptionMap.get(i))
					.filter((e) => e !== undefined && e !== null)
					.join(",");

		if (surface)
			keywords +=
				", " +
				surface
					.map((i) => SurfaceOptionMap.get(i) || "")
					.filter((e) => e !== undefined && e !== null)
					.join(",");

		if (type)
			keywords +=
				", " +
				type
					.map((i) => TypeOptionMap.get(i) || "")
					.filter((e) => e !== undefined && e !== null)
					.join(",");

		return keywords.substring(0, keywords.length);
	};

	const formatHostString = (hosts?: Array<RunOrganizerNode> | null) => {
		if (!hosts || _.isEmpty(hosts)) return;

		if (hosts.length > 1) {
			// only display the first two hosts
			return `${hosts[0].name} and ${hosts[1].name}`;
		}

		return hosts[0].name;
	};

	const ends = endDate ? new Date(endDate).toISOString() : "";
	const starts = startDate ? new Date(startDate).toISOString() : "";
	const host = formatHostString(hosts);

	return (
		<JsonLd<Event>
			item={{
				"@context": "https://schema.org",
				"@type": "Event",
				isAccessibleForFree: true,
				startDate: starts,
				endDate: ends,
				eventSchedule: {
					"@type": "Schedule",
					byDay: days.map((i) => DayOptionMap.get(i)).join(","),
					endDate: ends,
					startDate: starts
				},
				location: {
					"@type": "Place",
					address,
					name: host
				},
				keywords: formatKeywordString(difficulty, surface, type),
				name: name,
				organizer: {
					"@type": "Organization",
					name: host
				},
				url: `https://grouprunfinder.com/runs/${id}/${slug(name)}`
			}}
		/>
	);
};
