"use client";

import { GrfConfig } from "@/../grf.config";
import { Variant } from "@/components";
import { Days, Recurrence } from "@/lib/graphql/types";
import * as Strings from "@/lib/strings";
import { formatTimeWithOffset } from "@/lib/util/formatTimeWithOffset";
import { DateTime } from "luxon";
import { DayOptionMap, RecurrenceOptionMap } from "./optionMap";

export interface ScheduleProps {
	days: Days[];
	endDate?: string | null;
	recurs: Recurrence;
	startTime: string;
	startDate: string | undefined | null;
	variant?: Variant;
}

export const Schedule = ({ days, endDate, startDate, startTime, recurs, variant = "primary" }: ScheduleProps) => {
	const formatScheduleString = (
		days: Days[],
		recurs: Recurrence,
		startTime: string,
		startDate?: string | null,
		endDate?: string | null,
		separator = ", "
	) => {
		const dbDateFormatString = GrfConfig.dbDateFormatString;

		const d = days.map((i) => DayOptionMap.get(i)).join(separator);
		const r = RecurrenceOptionMap.get(recurs) || Strings.FallbackOptionLabel;
		const s = startDate && DateTime.fromFormat(startDate, dbDateFormatString).toLocaleString(DateTime.DATE_MED); //format(new Date(startDate), FormatDateString)) || undefined;
		const e = endDate && DateTime.fromFormat(endDate, dbDateFormatString).toLocaleString(DateTime.DATE_MED); //format(new Date(endDate), FormatDateString)) || undefined;
		const t = formatTimeWithOffset(startTime);

		let schedule;

		switch (recurs) {
			case Recurrence.D:
				// daily
				schedule = `${r} @ ${t}`;
				break;
			case Recurrence.S:
				// once; startDate should exist here
				schedule = `${d} ${s!} @ ${t}`;
				break;
			default:
				// everything else
				schedule = `${r} ${Strings.LabelOn} ${d} @ ${t}`;
				break;
		}

		// add end date if one was specified
		if (e && recurs != Recurrence.S) schedule = `${schedule} ${Strings.LabelUntil} ${e}`;

		return schedule;
	};

	const separator = variant === "primary" ? ", " : " / ";

	const schedule = formatScheduleString(days, recurs, startTime, startDate, endDate, separator);

	return variant === "primary" ? <div className="topInfo">{schedule}</div> : <p>{schedule}</p>;
};
