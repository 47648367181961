"use client";

import { GrfConfig } from "@/../grf.config";
import { Variant } from "@/components";
import { faQrcode } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClipboard } from "@mantine/hooks";
import { Dialog, Tooltip } from "@mui/material";
import Link from "next/link";
import { useState } from "react";
import QRCode from "react-qr-code";

export interface ShortlinkProps {
	link: string;
	title?: string;
	variant?: Variant;
	onClick?: () => void;
	onQrClick?: () => void;
}

export const Shortlink = ({ link, title, variant = "primary", onClick, onQrClick }: ShortlinkProps) => {
	const timeout = 3000;
	const initialShortLinkText = `${GrfConfig.shortlinkUrl}/${link}`;

	const [shortLinkText, setShortLinkText] = useState(initialShortLinkText);
	const [open, setOpen] = useState(false);
	const clipboard = useClipboard({ timeout });

	return variant === "primary" ? (
		<div className="meta">
			<span>
				{title && <h2>{title}</h2>}
				<Link
					href="#"
					rel="nofollow"
					style={{ color: "black" }}
					target="_blank"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						onQrClick && onQrClick();

						setOpen(true);
					}}
				>
					<Tooltip title="View QR Code">
						<FontAwesomeIcon icon={faQrcode} />
					</Tooltip>
				</Link>
				<Tooltip title="Copy Link">
					<Link
						href="#"
						rel="nofollow"
						style={{ color: "black" }}
						target="_blank"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							onClick && onClick();

							clipboard.copy(shortLinkText);
							setShortLinkText("Copied!");
							setTimeout(() => {
								setShortLinkText(initialShortLinkText);
							}, timeout);
						}}
					>
						{shortLinkText}
					</Link>
				</Tooltip>
				<Dialog
					open={open}
					onClose={() => {
						setOpen(false);
					}}
				>
					<QRCode
						size={512}
						style={{ backgroundColor: "#f7f7f7", padding: "12" }}
						value={initialShortLinkText}
					/>
				</Dialog>
			</span>
		</div>
	) : (
		<></>
	);
};
