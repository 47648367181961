import { GrfConfig } from "@/../grf.config";
import { CloseButton } from "@/components";
import Image from "next/image";
import { ReactNode } from "react";

export interface DetailHeaderProps {
	children?: ReactNode;
	featuredImage?: string;
	id: string;
	title: string;
	onClose?: () => void;
}

export const DetailHeader = ({ children, featuredImage, id, title, onClose }: DetailHeaderProps) => {
	const heading = (
		<div className="runName">
			<h1 className="theName">{title}</h1>
			{children}
		</div>
	);

	const closeIcon = <CloseButton onClick={onClose} />;

	return featuredImage ? (
		<div
			className="name withImage"
			style={{
				background: "linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.125))",
				position: "relative"
			}}
		>
			<Image
				alt="Group run featured image"
				blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88B8AAsUB4ZtvXtIAAAAASUVORK5CYII="
				fill
				key={`${featuredImage}_feat`}
				placeholder="blur"
				priority
				quality={50}
				sizes="(max-width: 600px) 100vw, 33vw"
				src={`${GrfConfig.cdnUrl}/runs/${id}/p/${featuredImage}`}
				style={{
					objectFit: "cover",
					width: "100%",
					zIndex: -1
				}}
			/>
			{closeIcon}
			{heading}
		</div>
	) : (
		<div className="name">
			{closeIcon}
			{heading}
		</div>
	);
};
