"use client";

import backgroundImage from "@/../public/background-02-1920x1440.webp";
import { Header } from "@/components";
import Image from "next/image";
import { ReactNode } from "react";

export interface LoginPageLayoutProps {
	children?: ReactNode;
}

export const LoginPageLayout = ({ children }: LoginPageLayoutProps) => {
	return (
		<>
			<div style={{ position: "relative" }} className="App front-page">
				<Image
					alt=""
					fill
					placeholder="blur"
					priority
					quality={30}
					sizes="100vw"
					src={backgroundImage}
					style={{ filter: "brightness(67%)", objectFit: "cover", objectPosition: "87% 0" }}
				/>
				<Header variant="login" />
				<div style={{ position: "relative", paddingTop: 0, height: "100vh" }} className="App-body-run">
					<div style={{ height: "100vh", minHeight: "595px", marginTop: 75 }}>{children}</div>
				</div>
			</div>
		</>
	);
};
