"use client";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CloseButtonProps {
	onClick?: () => void;
}

export const CloseButton = ({ onClick }: CloseButtonProps) => {
	return (
		<div className="closeDetails" style={{ cursor: "pointer" }} onClick={onClick}>
			<FontAwesomeIcon icon={faTimes} />
		</div>
	);
};
