var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"33f63719c7184ce3779454c3fe76aacf8a1244df"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as FullStory from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
	integrations: [
		new BrowserTracing(),
		new SentryFullStory(process.env.NEXT_PUBLIC_SENTRY_ORG, { client: FullStory }),
		new Sentry.Replay()
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || "0.2")
});
