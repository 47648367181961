"use client";

import { Variant } from "@/components";
import { IndexRuns, algolia } from "@/lib/services/algolia";
import { ReactNode, useRef } from "react";
import { Configure, InstantSearch, useInstantSearch } from "react-instantsearch";
import { Hits } from "../Hits";
import { Input } from "../Input";

const EmptyQueryBoundary = ({ children }: { children: ReactNode }) => {
	const { indexUiState } = useInstantSearch();
	return (indexUiState.query ? children : <></>) as JSX.Element;
};

export interface SearchProps {
	placeholder?: string;
	title?: string;
	variant?: Variant;
	onSearching?: (term: string) => void;
	onSearchCleared?: () => void;
	onSearchComplete?: (term: string, e?: Record<string, any>) => void;
}

export const Search = ({ placeholder, title, variant = "secondary", onSearchComplete }: SearchProps) => {
	const client = useRef(algolia(IndexRuns).client);

	return (
		<InstantSearch
			searchClient={client.current}
			indexName={IndexRuns}
			future={{ preserveSharedStateOnUnmount: false }}
			insights={false}
		>
			<Configure
				aroundLatLngViaIP={false}
				aroundRadius="all"
				hitsPerPage={25}
				analyticsTags={["web"]}
				distinct
				clickAnalytics
			/>
			<Input placeholder={placeholder} title={title} variant={variant} />
			<EmptyQueryBoundary>
				<Hits onSearchComplete={onSearchComplete} />
			</EmptyQueryBoundary>
		</InstantSearch>
	);
};
