import * as Fathom from "fathom-client";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useFathom = () => {
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
			if (!shallow) {
				Fathom.trackPageview();
			}
		};

		Fathom.load(process.env.NEXT_PUBLIC_FATHOM_TOKEN!, {
			excludedDomains: [process.env.NEXT_PUBLIC_FATHOM_EXCLUDED_DOMAINS as string]
		});

		router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [router.events]);
};
