import { DateTime } from "luxon";

const offsetToTimeZoneMap: Map<string, string> = new Map([
	["-12:00", "Etc/GMT+12"],
	["-11:00", "Pacific/Pago_Pago"],
	["-10:00", "Pacific/Honolulu"],
	["-09:00", "America/Anchorage"],
	["-08:00", "America/Los_Angeles"],
	["-07:00", "America/Denver"],
	["-06:00", "America/Chicago"],
	["-05:00", "America/New_York"],
	["-04:00", "America/Halifax"],
	["-03:30", "America/St_Johns"],
	["-03:00", "America/Argentina/Buenos_Aires"],
	["-02:00", "America/Noronha"],
	["-01:00", "Atlantic/Azores"],
	["+00:00", "UTC"],
	["+01:00", "Europe/Berlin"],
	["+02:00", "Europe/Athens"],
	["+03:00", "Europe/Moscow"],
	["+03:30", "Asia/Tehran"],
	["+04:00", "Asia/Dubai"],
	["+04:30", "Asia/Kabul"],
	["+05:00", "Asia/Karachi"],
	["+05:30", "Asia/Kolkata"],
	["+05:45", "Asia/Kathmandu"],
	["+06:00", "Asia/Almaty"],
	["+06:30", "Asia/Yangon"],
	["+07:00", "Asia/Bangkok"],
	["+08:00", "Asia/Singapore"],
	["+09:00", "Asia/Tokyo"],
	["+09:30", "Australia/Adelaide"],
	["+10:00", "Australia/Sydney"],
	["+11:00", "Pacific/Noumea"],
	["+12:00", "Pacific/Auckland"],
	["+13:00", "Pacific/Tongatapu"],
	["+14:00", "Pacific/Kiritimati"]
]);

export function formatTimeWithOffset(timeString: string): string {
	const offsetPrefix = timeString.indexOf("-") !== -1 ? "-" : "+";
	const [timePart, offsetPart] = timeString.split(offsetPrefix);
	const offset = `${offsetPrefix}${offsetPart}`;

	const timeZone = offsetToTimeZoneMap.get(offset) || "UTC";
	const dateTime = DateTime.fromISO(`${timePart}`, { zone: timeZone });

	return dateTime.toLocaleString({
		hour: "numeric",
		minute: "2-digit",
		hourCycle: "h12",
		timeZoneName: "short"
	});
}
