"use client";

import { AnalyticsProvider } from "@/hooks/useAnalytics";
import { useFathom } from "@/hooks/useFathom";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { ReactNode } from "react";

export const Analytics = ({ children }: { children?: ReactNode }) => {
	useFathom();

	return (
		<>
			<AnalyticsProvider>{children}</AnalyticsProvider>
			<VercelAnalytics />
		</>
	);
};
