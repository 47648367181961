import "@aws-amplify/ui-react/styles.css";

import "@/styles/app.css";
import "@/styles/globals.css";

import { GrfConfig } from "@/../grf.config";
import { AmplifyConfig, Analytics, Textblock } from "@/components";
import { useApollo } from "@/hooks/useApollo";
import { clientSideEmotionCache } from "@/lib/emotion";
import { theme } from "@/themes/grf/theme";
import { ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { Authenticator } from "@aws-amplify/ui-react";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { NextPage } from "next";
import { HubspotProvider } from "next-hubspot";
import type { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode } from "react";

console.log(`[GRF] v${GrfConfig.version} ⛰️`);

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

// required to support Page.getLayout
export type AppProps = NextAppProps & { Component: NextPageWithLayout };

// required to support injection of Apollo client
interface GrfAppProps extends AppProps {
	pageProps: {
		initialApolloState?: NormalizedCacheObject;
		[key: string]: any;
	};
}

export default function App({ Component, pageProps, ...props }: GrfAppProps) {
	const getLayout = Component.getLayout ?? ((page) => page);
	const apolloClient = useApollo(pageProps.initialApolloState);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { initialApolloState, ...componentProps } = pageProps;

	return (
		<>
			<SpeedInsights />
			<AmplifyConfig />
			<Textblock />
			<Authenticator.Provider>
				<ApolloProvider client={apolloClient}>
					<Analytics>
						<AppCacheProvider emotionCache={clientSideEmotionCache} {...props}>
							<Head>
								<meta name="viewport" content="width=device-width, initial-scale=1" />
							</Head>
							<StyledEngineProvider injectFirst>
								<ThemeProvider theme={theme}>
									<HubspotProvider>{getLayout(<Component {...componentProps} />)}</HubspotProvider>
								</ThemeProvider>
							</StyledEngineProvider>
						</AppCacheProvider>
					</Analytics>
				</ApolloProvider>
			</Authenticator.Provider>
		</>
	);
}
