import { Variant } from "@/components";
import { Link, useAuthenticator } from "@aws-amplify/ui-react";
import { faRightFromBracket, faRightToBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import { signOut } from "aws-amplify/auth";

export interface LoginButtonProps {
	isAuthenticated?: boolean;
	loginLabel?: string;
	logoutLabel?: string;
	variant: Variant;
	onLogin?: () => void;
	onLoginClick?: () => void;
	onLogout?: () => void;
	onLogoutClick?: () => void;
}

export const LoginButton = ({
	loginLabel = "Login",
	logoutLabel = "Logout",
	variant,
	onLoginClick,
	onLogout,
	onLogoutClick
}: LoginButtonProps) => {
	const { authStatus } = useAuthenticator((context) => [context.authStatus]);

	if (authStatus === "configuring") return <></>;

	return authStatus === "unauthenticated" ? (
		<Link id="headerLogin" href="/login" onClick={onLoginClick}>
			<Tooltip title={loginLabel}>
				<IconButton
					id="login"
					aria-label={loginLabel}
					color="inherit"
					sx={(theme) =>
						(() => {
							switch (variant) {
								case "primary":
									return {
										marginLeft: ".66em",
										color: "black",
										filter: "drop-shadow(0 0 .5em #FFFFFFD1)",
										fontSize: "1.75rem"
									};
								case "secondary":
									return {
										marginLeft: ".66em",
										color: "white",
										[theme.breakpoints.down("sm")]: {
											marginLeft: "0"
										}
									};
								case "error":
								default:
									return {
										marginLeft: ".66em",
										color: "#ffffff82",
										[theme.breakpoints.down("sm")]: {
											marginLeft: "0"
										}
									};
							}
						})()
					}
				>
					<FontAwesomeIcon icon={faRightToBracket} />
				</IconButton>
			</Tooltip>
		</Link>
	) : (
		<Tooltip title={logoutLabel}>
			<IconButton
				id="logout"
				aria-label={logoutLabel}
				color="inherit"
				onClick={() => {
					onLogoutClick && onLogoutClick();
					void signOut().then(() => {
						onLogout && onLogout();
					});
				}}
				sx={(theme) =>
					(() => {
						switch (variant) {
							case "primary":
								return {
									marginLeft: ".66em",
									color: "black",
									filter: "drop-shadow(0 0 .5em #FFFFFFD1)",
									fontSize: "1.75rem"
								};
							case "secondary":
								return {
									marginLeft: ".66em",
									color: "white",
									[theme.breakpoints.down("sm")]: {
										marginLeft: "0"
									}
								};
							case "error":
							default:
								return {
									marginLeft: ".66em",
									color: "#ffffff82",
									[theme.breakpoints.down("sm")]: {
										marginLeft: "0"
									}
								};
						}
					})()
				}
			>
				<FontAwesomeIcon icon={faRightFromBracket} />
			</IconButton>
		</Tooltip>
	);
};
