import { gql } from "@apollo/client";

export const ClubQueryBaseFields = gql(/* GraphQL */ `
	fragment ClubQueryBaseFields on ClubQueryResult {
		count
		cursor
		pageInfo {
			hasNextPage
			hasPreviousPage
			endCursor
			startCursor
		}
	}
`);

export const RunQueryBaseFields = gql(/* GraphQL */ `
	fragment RunQueryBaseFields on RunQueryResult {
		count
		cursor
		pageInfo {
			hasNextPage
			hasPreviousPage
			endCursor
			startCursor
		}
	}
`);

export const StoreQueryBaseFields = gql(/* GraphQL */ `
	fragment StoreQueryBaseFields on StoreQueryResult {
		count
		cursor
		pageInfo {
			hasNextPage
			hasPreviousPage
			endCursor
			startCursor
		}
	}
`);

export const UserQueryBaseFields = gql(/* GraphQL */ `
	fragment UserQueryBaseFields on UserQueryResult {
		count
		cursor
		pageInfo {
			hasNextPage
			hasPreviousPage
			endCursor
			startCursor
		}
	}
`);
