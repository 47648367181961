"use client";

import { Difficulty as RunDifficulty } from "@/lib/graphql/types";
import * as Strings from "@/lib/strings";
import { faSignal4 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { DifficultyOptionMap } from "./optionMap";

export interface DifficultyProps {
	difficulty: RunDifficulty[] | undefined | null;
}

export const Difficulty = ({ difficulty }: DifficultyProps) => {
	if (!difficulty || _.isEmpty(difficulty)) return <></>;

	return (
		<div className="meta">
			<span>
				<Tooltip title="Difficulty">
					<FontAwesomeIcon icon={faSignal4} />
				</Tooltip>
			</span>
			{(() => {
				return difficulty.map((i) => DifficultyOptionMap.get(i) || Strings.FallbackOptionLabel).join(", ");
			})()}
		</div>
	);
};
