import { AnalyticsBrowser } from "@segment/analytics-next";
import va from "@vercel/analytics";
import * as Fathom from "fathom-client";
import { ReactNode, createContext, useContext, useMemo } from "react";

const Events = {
	AccountConfirmed: "Account Confirmed",
	AccountConfirmedFailure: "Account Confirmed Failure",
	ButtonClicked: "Button Clicked",
	FormSubmitted: "Form Submitted",
	LinkClicked: "Link Clicked",
	MapPinClicked: "Map Pin Clicked",
	RunSubmitted: "Run Submitted",
	Searched: "Searched"
};

const Token = process.env.NEXT_PUBLIC_SEGMENT_TOKEN!;
const AnalyticsContext = createContext<{ analytics: AnalyticsBrowser; writeKey: string } | undefined>(undefined);

export interface AnalyticsProviderProps {
	children?: ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
	const analytics = useMemo(() => {
		return AnalyticsBrowser.load({ writeKey: Token });
	}, []);

	return <AnalyticsContext.Provider value={{ analytics, writeKey: Token }}>{children}</AnalyticsContext.Provider>;
};

const useSegment = () => {
	const context = useContext(AnalyticsContext);
	if (!context) {
		throw new Error("Analytics context was used outside of its provider.");
	}
	return context;
};

export const useAnalytics = () => {
	const segment = useSegment();
	const analytics = segment.analytics;

	const track = (name: string, props?: Record<string, any>) => {
		void analytics.track(name, props);
		va.track(name, props);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		Fathom.trackEvent(name);
	};

	return {
		analytics,
		events: Events,
		va,
		track
	};
};
