"use client";

import * as Strings from "@/lib/strings";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { MetadataOptionMap } from "./optionMap";

export interface MetadataProps {
	meta: string[] | undefined | null;
}

export const Metadata = ({ meta }: MetadataProps) => {
	if (!meta || _.isEmpty(meta)) return <></>;

	return (
		<div className="meta">
			<span>
				<Tooltip title="Other Details">
					<FontAwesomeIcon icon={faSparkles} />
				</Tooltip>
			</span>
			{(() => {
				return meta.map((i) => MetadataOptionMap.get(i) || Strings.FallbackOptionLabel).join(", ");
			})()}
		</div>
	);
};
