import { Variant } from "@/components";
import { Link as RunLink, LinkType as RunLinkType } from "@/lib/graphql/types";
import { faSquareFacebook, faSquareInstagram, faSquareXTwitter, faStrava } from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faShirtRunning } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import Link from "next/link";
import { LinksOptionMap as RunLinksOptionMap } from "./optionMap";

export interface LinksProps {
	links: RunLink[] | undefined | null;
	title?: string;
	variant?: Variant;
	onClick?: (type: string) => void;
}

export const Links = ({ links, title, variant = "primary", onClick }: LinksProps) => {
	if (!links || _.isEmpty(links) || variant === "secondary") return <></>;

	const linkIcon = (link: RunLink) => {
		switch (link.type) {
			case RunLinkType.Facebook:
				return <FontAwesomeIcon icon={faSquareFacebook} />;
				break;
			case RunLinkType.Instagram:
				return <FontAwesomeIcon icon={faSquareInstagram} />;
				break;
			case RunLinkType.Strava:
				return <FontAwesomeIcon icon={faStrava} />;
				break;
			case RunLinkType.Twitter:
				return <FontAwesomeIcon icon={faSquareXTwitter} />;
				break;
			case RunLinkType.Ultrasignup:
				return <FontAwesomeIcon icon={faShirtRunning} />;
				break;
			default:
				return <FontAwesomeIcon icon={faGlobe} />;
				break;
		}
	};

	return (
		<>
			{title && (
				<div className="meta">
					<h2>{title}</h2>
				</div>
			)}
			{_.orderBy(links, ["type"], ["asc"]).map((l, i) => {
				const type = RunLinksOptionMap.get(l.type);
				return (
					<div className="meta" key={i}>
						<span>
							<Tooltip title={type}>{linkIcon(l)}</Tooltip>
							<Link
								href={l.url}
								rel="ugc"
								style={{ color: "black" }}
								target="_blank"
								onClick={() => {
									onClick && type && onClick(type);
								}}
							>
								{type}
							</Link>
						</span>
					</div>
				);
			})}
		</>
	);
};
