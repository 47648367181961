"use client";

import { ReactNode } from "react";

export type AddressVariant = "compact" | "full" | "link" | "summary";

export interface AddressProps {
	address?: string;
	variant?: AddressVariant;
	navigation?: ReactNode;
}

export const Address = ({ address, navigation, variant = "summary" }: AddressProps) => {
	const formatAddressString = (address: string) => {
		const pieces = address.split(",");
		pieces.splice(0, pieces.length - 2);

		return pieces.join(", ");
	};

	const extractState = (address: string) => {
		return !address ? "" : address.substring(address.length - 12, address.length - 10);
	};

	if (!address) {
		return (
			<p>
				<span>No address provided</span>
			</p>
		);
	}

	// link - full address with maps navigation link
	if (variant === "link") {
		return (
			<div>
				<div className="topInfo directionslink">{navigation}</div>
			</div>
		);
	}

	// summary - city, state zip
	if (variant === "summary") {
		return (
			<p>
				<span>{formatAddressString(address)}</span>
			</p>
		);
	}

	// compact - state only
	if (variant === "compact") {
		return (
			<p>
				<span className="runAddress">{extractState(address)}</span>
			</p>
		);
	}

	// default - full address
	return (
		<p>
			<span className="topInfo directionslink">{address}</span>
		</p>
	);
};
