"use client";

import { LoginButton, LogoButton, MapButton, MapPinButton, Variant } from "@/components";
import { useAnalytics } from "@/hooks/useAnalytics";
import { AppBar, Toolbar } from "@mui/material";
import { ReactNode } from "react";

export interface HeaderProps {
	children?: ReactNode;
	variant: Variant;
}

export const Header = ({ children, variant }: HeaderProps) => {
	const location = "Header";
	const { analytics, events, track } = useAnalytics();

	return (
		<div style={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				sx={
					variant === "primary" || variant === "login"
						? {
								background: "none",
								minHeight: "10vh",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								fontSize: "calc(10px + 2vmin)",
								color: "white",
								boxShadow: "none"
						  }
						: {
								background: "linear-gradient(180deg, rgb(17, 34, 66) 0%, rgb(16, 46, 104) 100%)",
								minHeight: "10vh",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								fontSize: "calc(10px + 2vmin)",
								color: "white",
								boxShadow: "none"
						  }
				}
			>
				<Toolbar sx={{ paddingLeft: "1.33em" }} id="searching">
					<LogoButton
						alt="Group Run Finder Logo"
						href="/"
						variant={variant}
						onClick={() => {
							track(events.ButtonClicked, {
								name: "GRF Logo",
								location
							});
						}}
					/>
					{children}
					<div style={{ flexGrow: 1 }} />
					<div
						style={{
							display: "flex",
							marginRight: "1em",
							visibility: variant !== "login" ? "visible" : "hidden"
						}}
						id="navIcons"
					>
						<MapButton
							href="/runs"
							label="View Runs"
							variant={variant}
							onClick={() => {
								track(events.ButtonClicked, {
									name: "View Runs",
									location
								});
							}}
						/>
						<MapPinButton
							href="/runs/new"
							label="Add New Run"
							variant={variant}
							onClick={() => {
								track(events.ButtonClicked, {
									name: "Add New Run",
									location
								});
							}}
						/>
						<LoginButton
							variant={variant}
							onLoginClick={() => {
								track(events.ButtonClicked, {
									name: "Login",
									location
								});
							}}
							onLogoutClick={() => {
								track(events.ButtonClicked, {
									name: "Logout",
									location
								});
							}}
							onLogout={() => {
								track("Logged Out");
								void analytics.reset().then(() => {
									console.log("[GRF] Logged out 🧟");
									window.location.href = "/";
								});
							}}
						/>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
};
