import { GrfConfig } from "@/../grf.config";
import _ from "lodash";
import Image from "next/image";

export interface DetailImageCarouselProps {
	id: string;
	photos: string[] | undefined | null;
}

export const DetailImageCarousel = ({ id, photos }: DetailImageCarouselProps) => {
	if (!photos || _.isEmpty(photos) || photos?.length === 1) {
		// count == 1 test prevents re-duplication of featured image
		return <></>;
	}

	return (
		<>
			{photos.slice(1).map((item: string) => (
				<div
					key={item}
					className="photo"
					style={{
						position: "relative"
					}}
				>
					<Image
						alt="Group run image"
						blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88B8AAsUB4ZtvXtIAAAAASUVORK5CYII="
						fill
						placeholder="blur"
						quality={50}
						sizes="(max-width: 600px) 100vw, 33vw"
						src={`${GrfConfig.cdnUrl}/runs/${id}/p/${item}`}
						style={{
							objectFit: "cover",
							width: "100%",
							zIndex: 0
						}}
					/>
				</div>
			))}
		</>
	);
};
