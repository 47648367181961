"use client";

import { Grid } from "@mui/material";
import { ReactNode } from "react";

export interface ContentGridProps {
	children?: ReactNode;
}

export const ContentGrid = ({ children }: ContentGridProps) => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={9} className="pageCopy">
				<div className="document-page-copy">{children}</div>
			</Grid>
		</Grid>
	);
};
