"use client";

import { Variant } from "@/components";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Link from "next/link";

export interface MapButtonProps {
	href: string;
	label: string;
	variant: Variant;
	onClick?: () => void;
}

export const MapButton = ({ href, label, variant, onClick }: MapButtonProps) => {
	return (
		<Link id="headerRunMap" href={href} onClick={onClick}>
			<Tooltip title={label}>
				<IconButton
					id="view-runs"
					aria-label={label}
					color="inherit"
					sx={(theme) =>
						(() => {
							switch (variant) {
								case "primary":
									return {
										marginLeft: ".66em",
										color: "black",
										filter: "drop-shadow(0 0 .5em #FFFFFFD1)",
										fontSize: "1.75rem"
									};
								case "secondary":
									return {
										marginLeft: ".66em",
										color: "white",
										[theme.breakpoints.down("sm")]: {
											marginLeft: "0"
										}
									};
								case "error":
								default:
									return {
										marginLeft: ".66em",
										color: "#ffffff82",
										[theme.breakpoints.down("sm")]: {
											marginLeft: "0"
										}
									};
							}
						})()
					}
				>
					<FontAwesomeIcon icon={faMapMarkedAlt} />
				</IconButton>
			</Tooltip>
		</Link>
	);
};
