"use client";

import { Variant } from "@/components";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container, IconButton, InputAdornment, InputBase, TextField } from "@mui/material";
import { inputBaseClasses } from "@mui/material/InputBase";
import { useSearchBox } from "react-instantsearch";
import { Title } from "../Title";

export interface InputProps {
	placeholder?: string;
	title?: string;
	variant?: Variant;
}

export const Input = ({ placeholder, title, variant = "secondary" }: InputProps) => {
	return variant === "primary" ? (
		<InputPrimary placeholder={placeholder} title={title} />
	) : (
		<InputSecondary placeholder={placeholder} />
	);
};

const InputPrimary = ({ placeholder, title }: InputProps) => {
	const { query, refine, clear } = useSearchBox();

	return (
		<div className="titleAndSearch" id={query !== "" ? "searching" : "notsearching"}>
			<Title title={title} />
			<Container className="App-search" itemType="span">
				<TextField
					className="App-search-input"
					inputProps={{ "aria-label": "search for a group run" }}
					id="front-page-search"
					placeholder={placeholder || ""}
					variant="outlined"
					value={query}
					onChange={(event) => {
						refine(event.currentTarget.value);
					}}
					onKeyDown={(event) => {
						if (event.key === "Escape") {
							clear();
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<FontAwesomeIcon icon={faSearch} />
							</InputAdornment>
						)
					}}
				/>
			</Container>
		</div>
	);
};

const InputSecondary = ({ placeholder }: { placeholder?: string }) => {
	const { query, refine, clear } = useSearchBox();

	return (
		<>
			<Box
				sx={(theme) => ({
					padding: "0 .75em",
					height: "100%",
					position: "absolute",
					pointerEvents: "none",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					[theme.breakpoints.down("sm")]: {
						padding: "0 .5em 0 .66em"
					}
				})}
			>
				<SearchIcon
					sx={(theme) => ({
						fontSize: "31px",
						minWidth: "31px",
						fill: "#184693",
						[theme.breakpoints.down("sm")]: {
							fill: "#6500ff"
						}
					})}
				/>
			</Box>
			<InputBase
				placeholder={placeholder || ""}
				sx={(theme) => ({
					marginLeft: "2.66em",
					width: "100%",
					[`& .${inputBaseClasses.input}`]: {
						fontSize: "133%",
						padding: "1em 1em 0.9em 1em",
						width: "calc(100% - 7.33em)",
						[theme.breakpoints.down("sm")]: {
							width: "calc(100% - 6.75em)",
							fontSize: "120%",
							paddingRight: "0.66em",
							paddingLeft: ".25em",
							fontWeight: "500",
							letterSpacing: ".025em"
						}
					}
				})}
				inputProps={{ "aria-label": "search for a group run" }}
				value={query}
				onChange={(event) => {
					refine(event.currentTarget.value);
				}}
				onKeyDown={(event) => {
					if (event.key === "Escape") {
						clear();
					}
				}}
				endAdornment={
					<InputAdornment sx={{ fontSize: "100%" }} position="end">
						<IconButton
							style={query === "" ? { display: "none" } : { display: "block" }}
							onClick={() => clear()}
							aria-label="clear search"
							edge="end"
						>
							<CloseIcon sx={{ color: "white", fontSize: "1.5em" }} />
						</IconButton>
					</InputAdornment>
				}
			/>
		</>
	);
};
