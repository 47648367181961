export const DayOptionMap = new Map<string, string>([
	["M", "Monday"],
	["T", "Tuesday"],
	["W", "Wednesday"],
	["R", "Thursday"],
	["F", "Friday"],
	["S", "Saturday"],
	["U", "Sunday"]
]);

export const RecurrenceOptionMap = new Map<string, string>([
	["A", "Annually"],
	["B", "Bi-Weekly"],
	["D", "Daily"],
	["M", "Monthly"],
	["O", "Other"],
	["Q", "Quarterly"],
	["S", "Once"],
	["W", "Weekly"]
]);
