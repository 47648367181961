import { createServerRunner } from "@aws-amplify/adapter-nextjs";
import { Amplify, ResourcesConfig } from "aws-amplify";
import { I18n } from "aws-amplify/utils";

// https://docs.amplify.aws/gen1/javascript/tools/libraries/configure-categories/
// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#internationalization-i18n

export const AmplifyConfig = {
	API: {
		GraphQL: {
			apiKey: process.env.NEXT_PUBLIC_APPSYNC_API_KEY,
			defaultAuthMode: "iam",
			endpoint: process.env.NEXT_PUBLIC_URL_API!,
			region: "us-east-1"
		}
	},
	Auth: {
		Cognito: {
			allowGuestAccess: true,
			identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID!,
			userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID!,
			userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID!
		}
	}
} satisfies ResourcesConfig;

export const configure = () => {
	console.debug("[GRF] Bootstrapping backend");

	Amplify.configure(AmplifyConfig, { ssr: true });

	I18n.putVocabulariesForLanguage("en", {
		"Confirm Password": "Confirm password",
		"Create Account": "Register",
		"Resend Code": "Resend",
		"Sign In": "Login",
		"Sign in": "Login",
		"We Emailed You": "One more step!",
		"We Sent A Code": "One more step!",
		"Your code is on the way. To log in, enter the code we emailed to":
			"A verification code is on the way to you at",
		"Your code is on the way. To log in, enter the code we sent you":
			"A verification code is on the way. To log in, enter the code we sent you",
		"Your code is on the way. To log in, enter the code we texted to":
			"A verification code is on the way to you at",
		"It may take a minute to arrive": "It may take a few minutes to arrive"
	});
};

export const { runWithAmplifyServerContext } = createServerRunner({
	config: AmplifyConfig
});
