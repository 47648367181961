"use client";

import * as Strings from "@/lib/strings";
import { faMountains } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { VertOptionMap } from "./optionMap";

export interface VertProps {
	vert: string[] | undefined | null;
}

export const Vert = ({ vert }: VertProps) => {
	if (!vert || _.isEmpty(vert)) return <></>;

	return (
		<div className="meta">
			<span>
				<Tooltip title="Vert">
					<FontAwesomeIcon icon={faMountains} />
				</Tooltip>
			</span>
			{(() => {
				return vert.map((i) => VertOptionMap.get(i) || Strings.FallbackOptionLabel).join(", ");
			})()}
		</div>
	);
};
