"use client";

import { GrfConfig } from "@/../grf.config";
import Head from "next/head";
import { ReactNode } from "react";

export interface MetaProps {
	canonical?: string;
	children?: ReactNode;
	description: string;
	image?: string;
	title: string;
}

export const Meta = ({ canonical, description, image, title, children }: MetaProps) => {
	const DefaultOpenGraphImage = `${GrfConfig.cdnUrl}/og.png`;

	return (
		<Head>
			<title>{`${title} - Group Run Finder`}</title>
			{canonical && <link rel="canonical" href={canonical} />}
			<meta name="description" content={description.replace("..", ".")} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image ?? DefaultOpenGraphImage} />
			<meta property="og:title" content={title} />
			{canonical && <meta property="og:url" content={canonical} />}
			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:description" content={description} />
			<meta property="twitter:image" content={image ?? DefaultOpenGraphImage } />
			<meta property="twitter:title" content={title} />
			{children}
		</Head>
	);
};
