export const MetadataOptionMap = new Map<string, string>([
	["adaptive", "Adaptive"],
	["dogs_okay", "Dog friendly"],
	["ladies_only", "Ladies only"],
	["kids_okay", "Kid friendly"],
	["new", "New group"],
	["no_drop", "No drop"],
	["rain_or_shine", "Rain or shine"],
	["sober", "Sober"],
	["walkers_okay", "Walker friendly"],
	["virtual", "Virtual"]
]);
