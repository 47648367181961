"use client";

import backgroundImage from "@/../public/background-02-1920x1440.webp";
import { Footer, Header } from "@/components";
import Image from "next/image";
import { ReactNode } from "react";

export interface HomePageLayoutProps {
	children?: ReactNode;
}

export const HomePageLayout = ({ children }: HomePageLayoutProps) => {
	return (
		<>
			<div style={{ position: "relative" }} className="App front-page">
				<Image
					alt=""
					fill
					placeholder="blur"
					priority
					quality={30}
					sizes="100vw"
					src={backgroundImage}
					style={{ filter: "brightness(87%)", objectFit: "cover", objectPosition: "87% 0" }}
				/>
				<Header variant="primary" />
				<div style={{ position: "relative", paddingTop: 0, height: "90vh" }} className="App-body">
					<div style={{ height: "90vh", minHeight: "595px" }} className="front">
						{children}
						<Footer />
					</div>
				</div>
			</div>
		</>
	);
};
