"use client";

import { Address, Distance, Host, Schedule, Surface } from "@/components";
import { useAnalytics } from "@/hooks/useAnalytics";
import { Run } from "@/lib/graphql/types";
import { reduce } from "@/lib/util/hostReducer";
import { slug } from "@/lib/util/slug";
import { Hit as AlgoliaHit } from "instantsearch.js";
import Link from "next/link";
import { useInstantSearch } from "react-instantsearch";

export interface HitProps {
	hit: AlgoliaHit;
	onClick?: () => void;
}

export const Hit = ({ hit, onClick }: HitProps) => {
	const variant = "secondary";

	const { events, track } = useAnalytics();
	const { results } = useInstantSearch();

	if (!hit) return <></>;

	const hitName = hit["name"] as string;
	const { objectID, __position: position, __queryID: queryId } = hit;
	const run = hit as unknown as Run;
	const idx = encodeURIComponent(Buffer.from(results.index, "binary").toString("base64"));
	const { hosts } = reduce(run);

	return (
		<>
			<Link
				href={`/runs/${objectID}/${slug(hitName)}?i=${idx}&q=${queryId as string}`}
				onClick={() => {
					onClick && onClick();
					track(events.LinkClicked, {
						eventType: "click",
						index: results.index,
						location: "Search Hit",
						name: hitName,
						objectID,
						position,
						queryId: queryId ?? "",
						type: "run",
						uid: objectID
					});
				}}
			>
				<div>
					<p>{run.name}</p>
					<Host hosts={hosts} variant={variant} />
					<Schedule
						days={run.days}
						recurs={run.recurs}
						startDate={run.startDate}
						startTime={run.startTime}
						endDate={run.endDate}
						variant={variant}
					/>
					<p>
						<Distance distance={run.distance} variant={variant} />
						{run.distance && run.surface && <>&nbsp;—&nbsp;</>}
						<Surface surface={run.surface} variant={variant} />
					</p>
					<Address address={run.location?.address} />
				</div>
			</Link>
		</>
	);
};
