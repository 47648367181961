"use client";

import { Footer, Header, Variant } from "@/components";
import { Search } from "@/components/search/algolia";
import { useAnalytics } from "@/hooks/useAnalytics";
import { Box } from "@mui/material";
import { ReactNode } from "react";

export interface SecondaryPageLayoutProps {
	children?: ReactNode;
	variant?: Variant;
	onSearch?: (term: string, e?: Record<string, any>) => void;
}

export const SecondaryPageLayout = ({ children, variant = "secondary", onSearch }: SecondaryPageLayoutProps) => {
	const { analytics, events, va } = useAnalytics();
	return (
		<>
			<div className="App">
				<Header variant={variant}>
					<Box
						id="app-bar-search"
						sx={(theme) => ({
							position: "relative",
							borderRadius: ".25em",
							backgroundColor: "#000",
							marginRight: "1.33em",
							marginLeft: 0,
							width: "100%",
							overflow: "hidden",
							[theme.breakpoints.down("sm")]: {
								marginRight: "1em"
							}
						})}
					>
						<Search
							variant={variant}
							onSearchComplete={(term, e) => {
								onSearch && onSearch(term, e);
								const n = events.Searched;
								const p = { location: "header", term };
								void analytics.track(n, {
									...p,
									...e
								});
								va.track(n, { ...p });
							}}
						/>
					</Box>
				</Header>
				<div className="App-body">
					{children}
					<Footer />
				</div>
			</div>
		</>
	);
};
