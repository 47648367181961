export const textblockConfig = [
	{
		target: ".App-body-run .MuiGrid-root .headerCopy .top-of-page-copy, .App-body-run .MuiGrid-root .pageCopy .document-page-copy",
		minWidth: 320,
		maxWidth: 880,
		minWidth_FontSize: 1.17,
		maxWidth_FontSize: 1.35,
		minWidth_LineHeight: 1.21,
		maxWidth_LineHeight: 1.23,
		container: "self",
		units: "em"
	},
	{
		target: "#runDetails",
		minWidth: 320,
		maxWidth: 800,
		minWidth_FontSize: 0.95,
		maxWidth_FontSize: 1.1,
		minWidth_LineHeight: 1.0,
		maxWidth_LineHeight: 1.1,
		container: "self",
		units: "em"
	},
	{
		target: ".App-body .front .titleAndSearch h1",
		minWidth: 320,
		maxWidth: 1024,
		minWidth_FontSize: 3.2,
		maxWidth_FontSize: 3.7,
		minWidth_LineHeight: 0.95,
		maxWidth_LineHeight: 1,
		container: "self",
		units: "em"
	},
	{
		target: ".App-search-input .MuiInputBase-root input",
		minWidth: 320,
		maxWidth: 1024,
		minWidth_FontSize: 1.2,
		maxWidth_FontSize: 1.6,
		minWidth_LineHeight: 1,
		maxWidth_LineHeight: 1,
		container: "self",
		units: "em"
	},
	{
		target: ".nameAndNotes",
		minWidth: 320,
		maxWidth: 620,
		minWidth_FontSize: 0.95,
		maxWidth_FontSize: 1.1,
		minWidth_LineHeight: 1.17,
		maxWidth_LineHeight: 1.21,
		container: "self",
		units: "em"
	},
	{
		target: ".App .App-body-run .MuiGrid-root .standard-size-form-element .MuiContainer-root",
		minWidth: 280,
		maxWidth: 477,
		minWidth_FontSize: 0.9,
		maxWidth_FontSize: 1.1,
		minWidth_LineHeight: 1.17,
		maxWidth_LineHeight: 1.21,
		container: "self",
		units: "em"
	},
	{
		target: ".App-body > .footer-content, .App-body > form > .footer-content, .runMap > .footer-content",
		minWidth: 320,
		maxWidth: 600,
		minWidth_FontSize: 0.75,
		maxWidth_FontSize: 0.8,
		minWidth_LineHeight: 1.33,
		maxWidth_LineHeight: 1.33,
		container: "parent",
		units: "em"
	},
	{
		target: ".front > .footer-content",
		minWidth: 320,
		maxWidth: 600,
		minWidth_FontSize: 0.7,
		maxWidth_FontSize: 0.75,
		minWidth_LineHeight: 1.33,
		maxWidth_LineHeight: 1.33,
		container: "parent",
		units: "em"
	},
	{
		target: ".notFound > p",
		minWidth: 280,
		maxWidth: 1024,
		minWidth_FontSize: 2.4,
		maxWidth_FontSize: 4.8,
		minWidth_LineHeight: 1.2,
		maxWidth_LineHeight: 1.2,
		container: "self",
		units: "em"
	}
];
