"use client";

import { RunType } from "@/lib/graphql/types";
import * as Strings from "@/lib/strings";
import { faTasks } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { TypeOptionMap } from "./optionMap";

export interface TypeProps {
	type: RunType[] | undefined | null;
}

export const Type = ({ type }: TypeProps) => {
	if (!type || _.isEmpty(type)) return <></>;

	return (
		<div className="meta">
			<span>
				<Tooltip title="Type">
					<FontAwesomeIcon icon={faTasks} />
				</Tooltip>
			</span>
			{}
			{(() => {
				return type.map((i) => TypeOptionMap.get(i) || Strings.FallbackOptionLabel).join(", ");
			})()}
		</div>
	);
};
