import algoliasearch from "algoliasearch";
import { NextApiRequest, NextApiResponse } from "next";

const SyncUsername = process.env.ALGOLIA_SYNC_USERNAME;
const SyncPassword = process.env.ALGOLIA_SYNC_PASSWORD;

export const IndexClubs = process.env.NEXT_PUBLIC_ALGOLIA_IDX_CLUBS!;
export const IndexRuns = process.env.NEXT_PUBLIC_ALGOLIA_IDX_RUNS!;
export const IndexStores = process.env.NEXT_PUBLIC_ALGOLIA_IDX_STORES!;
export const Indexes = [IndexClubs, IndexRuns, IndexStores]; // TODO: make this object type? (check aws proj)

// TODO: change server to use typeof window === "undefined"

export const algolia = (idx: string = IndexRuns, server = false) => {
	//const server = typeof window === "undefined";

	const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!;
	const apiKey = !server ? process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN! : process.env.ALGOLIA_WRITE_TOKEN!;
	const client = algoliasearch(appId, apiKey);
	const index = client.initIndex(idx);

	return {
		client,
		index
	};
};

export const syncBasicAuthMiddleware = (req: NextApiRequest, res: NextApiResponse) => {
	const auth = req.headers.authorization;

	if (!auth) {
		res.setHeader("WWW-Authenticate", 'Basic realm="Algolia Sync"');
		res.status(401).end("Authentication required");
		return false;
	}

	const [username, password] = Buffer.from(auth.split(" ")[1], "base64").toString().split(":");

	if (username !== SyncUsername || password !== SyncPassword) {
		res.setHeader("WWW-Authenticate", 'Basic realm="Algolia Sync"');
		res.status(401).end("Unable to connect");
		return false;
	}

	return true;
};
