"use client";

import { RunOrganizerNode } from "@/lib/graphql/types";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

export interface OrganizerProps {
	organizer: RunOrganizerNode | undefined | null;
	showContactInfo?: boolean;
	onContactClick?: () => void;
}

export const Organizer = ({ showContactInfo = false, organizer, onContactClick }: OrganizerProps) => {
	const Cheetah = "cheetahTar";
	const Rabbit = "rabbitTar";
	const Sloth = "slothTar";
	const Turtle = "turtleTar";

	const hashOrganizerName = (name?: string) => {
		if (!name) return Sloth;

		let hash = 0;
		for (let i = 0; i < name.length; i++) {
			hash += Math.pow(name.charCodeAt(i) * 31, name.length - i);

			// tslint:disable-next-line:no-bitwise
			hash = hash & hash; // Convert to 32bit integer
		}

		if (hash > 0 && hash % 2 === 0) {
			return Cheetah;
		} else if (hash < 0 && hash % 2 === 0) {
			return Turtle;
		} else if (hash > 0 && Math.abs(hash % 2) === 1) {
			return Rabbit;
		} else {
			return Sloth;
		}
	};

	const [avatar, setAvatar] = useState("");
	useEffect(() => {
		organizer && setAvatar(hashOrganizerName(organizer.name));
	}, [organizer]);

	return (
		<>
			{organizer && organizer.name && (
				<div className="topInfo organizer">
					<span id="organizerAvatar" className={avatar}>
						<Image
							src="/avatar-sloth.svg"
							id="slothAvatar"
							alt="Organizer Avatar, Sloth"
							width={473}
							height={473}
						/>
						<Image
							src="/avatar-turtle.svg"
							id="turtleAvatar"
							alt="Organizer Avatar, Turtle"
							width={473}
							height={473}
						/>
						<Image
							src="/avatar-rabbit.svg"
							id="rabbitAvatar"
							alt="Organizer Avatar, Rabbit"
							width={473}
							height={473}
						/>
						<Image
							src="/avatar-cheetah.svg"
							id="cheetahAvatar"
							alt="Organizer Avatar, Cheetah"
							width={473}
							height={473}
						/>
					</span>
					<span className="organizerName">{organizer.name}</span>, Organizer&nbsp;
					{organizer.email && showContactInfo && (
						<Link href={`mailto:${organizer.email}`} onClick={onContactClick}>
							email
						</Link>
					)}
				</div>
			)}
		</>
	);
};
