"use client";

import { useClickOutside, useDebouncedState } from "@mantine/hooks";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { UseHitsProps, useHits, useSearchBox } from "react-instantsearch";
import { Hit } from "./Hit";

export type HitsProps = UseHitsProps & { onSearchComplete?: (term: string, e?: Record<string, any>) => void };

export const Hits = (props: HitsProps) => {
	const [query, setQuery] = useDebouncedState<{ term: string; e: Record<string, any> } | null>(null, 2000);
	const { hits, results } = useHits(props);
	const { clear } = useSearchBox();
	const ref = useClickOutside(() => clear());
	const { onSearchComplete } = props;
	const pathName = usePathname();

	useEffect(() => {
		onSearchComplete && query && query.term && onSearchComplete(query.term, query.e);
	}, [onSearchComplete, query]);

	useEffect(() => {
		results &&
			setQuery({
				term: results.query,
				e: {
					filters: [],
					eventType: "view",
					queryId: results.queryID,
					objectIds: hits.map((hit) => hit.objectID),
					positions: hits.map((hit) => hit.__position),
					index: results.index
				}
			});
	}, [hits, results, setQuery]);

	return (
		<div className="ais-Hits" ref={ref}>
			<ol className="ais-Hits-list">
				{hits.map((hit) => (
					<li className="ais-Hits-item" key={hit.objectID}>
						<Hit
							hit={hit}
							key={hit.objectID}
							onClick={() => {
								if (pathName !== "/") {
									clear();
								}
							}}
						/>
					</li>
				))}
			</ol>
		</div>
	);
};
