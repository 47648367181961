"use client";

import { Variant } from "@/components";
import { RunEdge } from "@/lib/graphql/types";
import { slug } from "@/lib/util/slug";
import { faPeopleGroup } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

export interface SimilarRunsProps {
	runs: RunEdge[] | undefined | null;
	title?: string;
	variant?: Variant;
	onRunClick?: (id: string, name: string) => void;
}

export const SimilarRuns = ({ runs, title, variant = "primary", onRunClick }: SimilarRunsProps) => {
	if (!runs) return <></>;

	return variant === "primary" ? (
		<div
			style={{
				fontSize: "100%",
				lineHeight: 1.2,
				fontWeight: 400,
				margin: "0 1rem 0.5em"
			}}
		>
			<span>
				{title && <h2>{title}</h2>}
				{runs.map((i) => (
					<div
						key={i.runId}
						style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", lineHeight: 1.6 }}
					>
						<FontAwesomeIcon icon={faPeopleGroup} />
						<Link
							style={{ color: "black" }}
							href={`/runs/${i.runId}/${slug(i.runName!)}`}
							onClick={() => {
								onRunClick && onRunClick(i.runId, i.runName!);
							}}
						>
							{i.runName}
						</Link>
					</div>
				))}
			</span>
		</div>
	) : (
		<></>
	);
};
